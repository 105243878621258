
import Vue from "vue";

import {
  propertyChangeRequestMapActions,
  propertyChangeRequestMapGetters,
  propertyChangeRequestMapState
} from "@/store/modules/propertychangerequest";
import { companiesMapGetters } from "@/store/modules/companies";
import { object } from "dot-object";
import { authMapGetters } from "@/store/modules/auth";
import { capitalizeFirstLetter } from "../../../../helpers/generalHelpers";
import { printSingleChangeRequest } from "@/helpers/downloadLossForm";

interface IData {
  errorMessage: string;
  successMessage: string;
  resourceNotFound: boolean;
  didInit: boolean;
  propertyChangeRequestData: any;
  pageDataInitialized: boolean;
  statusOptions: { label: string; value: string }[];
}

export default Vue.extend({
  name: "PropertyChangeRequestDetailsViewLegacy",

  data(): IData {
    return {
      errorMessage: "",
      successMessage: "",
      resourceNotFound: false,
      didInit: false,
      propertyChangeRequestData: {},
      pageDataInitialized: false,
      statusOptions: [
        {
          label: "Not Submitted",
          value: "Not Submitted"
        },
        {
          label: "Submitted",
          value: "Submitted"
        },
        {
          label: "Received",
          value: "Received"
        },
        {
          label: "Issued",
          value: "Issued"
        }
      ]
    };
  },
  components: {
    ViewChangeRequestInsurerDetails: () =>
      import(
        "@/components/ChangeRequests/ViewChangeRequestInsurerDetailsLegacy.vue"
      ),
    ViewChangeRequestPolicyDetails: () =>
      import(
        "@/components/ChangeRequests/ViewChangeRequestPolicyDetailsLegacy.vue"
      ),
    DisplayAttachment: () =>
      import("@/components/DataView/DisplayAttachment.vue")
  },
  computed: {
    actions(): any {
      return this.propertyChangeRequestData.actions || {};
    },
    descriptions(): any {
      return this.propertyChangeRequestData.description || {};
    },
    mortgage(): any {
      return this.propertyChangeRequestData.mortgage || {};
    },
    ...authMapGetters(["getCurrentUser"]),
    propertyChangeRequestId(): string {
      return this.$route.params.propertychangerequest_id;
    },
    ...propertyChangeRequestMapState(["makingApiRequest", "editing"]),
    ...propertyChangeRequestMapGetters(["getPropertyChangeRequestById"]),
    original(): any {
      return this.getPropertyChangeRequestById(this.propertyChangeRequestId);
    },
    ...companiesMapGetters(["companies"]),
    agentApplicationID(): any {
      return this.$route.params.agentApplicationID;
    },
    topButtonSecondaryItems(): {
      text: string;
      key: string;
      subItems: any[];
    } | null {
      return {
        text: "Actions",
        key: "actions",
        subItems: [
          { title: "Back", command: "back" },
          { title: "Print", command: "print" }
        ]
      };
    }
  },
  created() {
    this.initializePageData();
  },
  destroyed() {},
  methods: {
    ...propertyChangeRequestMapActions(["getPropertyChangeRequest"]),
    // function to get the property of the object
    getProperty(data: object, property: keyof typeof object): string {
      return data ? data[property] : "";
    },
    getValue(data: object, property: string): string {
      return data ? `${data}.${property}` : "";
    },
    onToolBarSelectItem(event: string) {
      switch (event) {
        case "back":
          this.$router.push(`/propertyChangeRequests`).catch(() => {});
          break;
        case "print":
          this.printCallback();
          break;
        default:
          break;
      }
    },
    async initializePageData() {
      try {
        await this.getPropertyChangeRequest(this.propertyChangeRequestId);
        const { data, ...rootFields } = this.editing as any;
        this.propertyChangeRequestData = {
          ...rootFields,
          ...data
        };
        this.pageDataInitialized = true;
      } catch (error) {
        this.resourceNotFound = true;
        this.$bugSnagClient.notify(error);
      }
    },
    capitalizeFirstLetter(word: string) {
      return capitalizeFirstLetter(word);
    },
    async printCallback() {
      const policyPrefix =
        this.editing &&
        this.editing.data &&
        this.editing.data.policyInfo &&
        this.editing.data.policyInfo.policyPrefix
          ? this.editing.data.policyInfo.policyPrefix
          : undefined;
      await printSingleChangeRequest.bind(this)({
        changeRequestIds: this.editing?._id,
        policyPrefix,
        printAction: "propertychangerequest/printPropertyChangeRequest"
      });
    }
  }
});
