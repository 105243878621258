var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage,"showSearch":false,"topActionButtonSecondary":_vm.topButtonSecondaryItems},on:{"errorMessageClosed":function($event){_vm.errorMessage = ''},"toolbarSelectItem":_vm.onToolBarSelectItem}},[(_vm.pageDataInitialized)?_c('div',{staticClass:"flex flex-col sm:flex-row border-b pb-4 mb-4"},[_c('div',{staticClass:"w-full sm:w-1/2 pr-0 sm:pr-4"},[_c('ViewChangeRequestInsurerDetails',{attrs:{"changeRequestData":_vm.propertyChangeRequestData}})],1),_c('div',{staticClass:"w-full sm:w-1/2 mt-8 sm:mt-0"},[_c('ViewChangeRequestPolicyDetails',{attrs:{"changeRequestData":_vm.propertyChangeRequestData}}),(_vm.descriptions && _vm.actions)?_c('div',[(
            _vm.actions.endorsementAction && _vm.descriptions.endorsementDescription
          )?_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"text-lg font-medium"},[_c('span',[_vm._v("Endorsements")])])]):_vm._e(),_c('div',{staticClass:"mb-2 pb-4"},[(_vm.actions.endorsementAction)?_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"font-medium"},[_vm._v("Action")]),_c('div',[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(_vm.actions.endorsementAction))+" ")])]):_vm._e(),(_vm.descriptions.endorsementDescription)?_c('div',[_c('div',{staticClass:"font-medium"},[_vm._v("Description")]),_c('div',[_vm._v(" "+_vm._s(_vm.description.endorsementDescription)+" ")])]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"mb-2"},[(_vm.actions.limitAction && _vm.descriptions.limitDescription)?_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"text-lg font-medium"},[_c('span',[_vm._v("Coverage/Limits")])])]):_vm._e(),_c('div',{staticClass:"mb-2 pb-4"},[(_vm.actions.limitAction)?_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"font-medium"},[_vm._v("Action")]),_c('div',[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(_vm.actions.limitAction))+" ")])]):_vm._e(),(_vm.descriptions.limitDescription)?_c('div',[_c('div',{staticClass:"font-medium"},[_vm._v("Description")]),_c('div',[_vm._v(" "+_vm._s(_vm.descriptions.limitDescription)+" ")])]):_vm._e()])]),(
          _vm.actions.mortgageAction ||
            _vm.mortgage.loanNumber ||
            _vm.mortgage.listed ||
            _vm.mortgage.billed ||
            _vm.mortgage.remarks ||
            _vm.mortgage.mortgage
        )?_c('div',{staticClass:"border-t pt-2"},[_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"text-lg font-medium"},[_c('span',[_vm._v("Mortgage")])])]),_c('div',{staticClass:"mb-2 flex justify-between w-full pb-4"},[(_vm.actions.mortgageAction)?_c('div',{staticClass:"w-6/12"},[_c('div',{staticClass:"font-medium"},[_vm._v("Action")]),_c('div',[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(_vm.actions.mortgageAction))+" ")])]):_vm._e(),(_vm.mortgage.loanNumber)?_c('div',{staticClass:"w-6/12"},[_c('div',{staticClass:"font-medium"},[_vm._v("Loan Number")]),_c('div',[_vm._v(" "+_vm._s(_vm.mortgage.loanNumber)+" ")])]):_vm._e()]),_c('div',{staticClass:"mb-2 w-full flex justify-between pb-4"},[(_vm.mortgage.listed)?_c('div',{staticClass:"w-6/12"},[_c('div',{staticClass:"font-medium"},[_vm._v("Listed")]),_c('div',[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(_vm.mortgage.listed))+" ")])]):_vm._e(),(_vm.mortgage.billed)?_c('div',{staticClass:"w-6/12"},[_c('div',{staticClass:"font-medium"},[_vm._v("Billed")]),_c('div',[_vm._v(" "+_vm._s(_vm.mortgage.billed)+" ")])]):_vm._e()]),_c('div',{staticClass:"mb-2 pb-4"},[(_vm.mortgage.mortgage)?_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"font-medium"},[_vm._v("Mortgage")]),_c('div',[_vm._v(" "+_vm._s(_vm.mortgage.mortgage)+" ")])]):_vm._e(),(_vm.mortgage.remarks)?_c('div',[_c('div',{staticClass:"font-medium"},[_vm._v("Remarks")]),_c('div',[_vm._v(" "+_vm._s(_vm.mortgage.remarks)+" ")])]):_vm._e()])]):_vm._e()],1)]):_vm._e(),(_vm.propertyChangeRequestData.attachments)?_c('DisplayAttachment',{attrs:{"attachments":_vm.propertyChangeRequestData.attachments}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }